<template>
  <div class="mm-main-page">
    <WarningMessage />
    <Header
      hide-search
      is-erp
    />
    <slot />
    <Footer />
    <GTM />
    <ModalsContainer />
  </div>
</template>

<script lang="ts" setup>
import GTM from 'shared/components/GTM.vue';
import Header from 'components/layouts/default/Header.vue';
import Footer from 'components/layouts/default/Footer.vue';

import { useClientGuard } from 'composables/useClientGuard';
import { UserHelper } from 'utils/userHelper.util';

import { useMainTitle } from 'composables/useMainTitle';

import { useServerSsoAuth } from 'composables/useAuthHelper';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';

setAuthCookieHeaders();
const isDomainAuth = await useDomainAuth();

await useServerSsoAuth();

useMainTitle();

await useSetServerUserInfo();

useCheckInternalUser();

if (!isDomainAuth && !UserHelper.isClient) {
  navigateTo('/');
}

useSetClientUserInfo();

onBeforeMount(async () => useClientGuard());
</script>

<style lang="scss" scoped>
.mm-main-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &.overflow {
    overflow: hidden;
  }
}

@media only screen and (max-width: 1279px) {
  .mm-main-page {
    :deep(.footer) {
      .footer__col:nth-child(2) {
        display: none;
      }
    }
  }

  :deep(.mm-header) {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  :deep(.mm-header) {
    margin-top: 30px;
  }
}
</style>
